import React from "react"
import { Link } from "gatsby"
import { parseImageUrl } from '@conradlin/notabase/src/utils'

export default ({ data }) => {
    const { title, tags, cover_image, publish_date, desc, read_time, url, slug } = data
    let coverimageURL = parseImageUrl(cover_image[0], 1000, slug)

    return (
        <div className="shadow-sm p-0 col-12 col-md-5 col-lg-5 my-4 mx-md-3 rounded grow bg-card">        
            <Link to={`posts/${url}/`}>
              <img 
                className="mx-auto"
                alt={`${title} cover image`}
                style={{ width: '100%', borderRadius: '0.25em 0.25em 0 0' }}
                src={coverimageURL}
              />
              <div className="my-1 mx-3">
              <pre className="mb-0" style = {{color: "grey", textAlign: "right", fontWeight: "400"}}>📅&nbsp;{publish_date.startDate}</pre>
              <pre className="mb-0" style = {{color: "grey", textAlign: "right", fontWeight: "400"}}>⏱&nbsp;{read_time} MIN</pre>
              <h2 className="mt-1 mb-3" style = {{ color: "black", fontWeight: "700" }}>{title}</h2>
              <p style = {{ color: "grey" }} dangerouslySetInnerHTML={{ __html: desc }}></p>
              </div>
            </Link>
        </div>
    )
}