import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import PostItem from "../components/postItem"
import Layout from '../components/layout'

const Blog = (props) => {
  const { data: { allPosts } } = props
  return (
    <Layout>
      <Helmet>
        <title>🗞 Balance ton hacker - Le Blog</title>
        <meta name="description" content="Découvrez tout les articles Balance ton Hacker : enquêtes, tutoriel, alertes." />
      </Helmet>
      <div id= "main">
      <div className="container w-100 p-0">
      <div className="row justify-content-center mx-auto mx-lg-0">
        {
          allPosts.nodes.map(node => <PostItem data={node} />)
        }
      </div>
      </div>
      </div>
    </Layout>
  )
}

export default Blog
export const query = graphql`
  query {
    allPosts(filter: {status: {eq: "published"}, content_type: {eq: "article"}}  sort: { fields: [publish_date___startDate], order: DESC }) {
      nodes {
        title
        tags
        desc
        content_type
        status
        url
        read_time
        cover_image
        slug
        publish_date{
          startDate(formatString: "DD-MM-YYYY", fromNow: false)
        }
      }
    }
  }
`